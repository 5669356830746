<form #form class="card" (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
    <div class="card-body">
        <button type="button" class="close" attr.aria-label="{{'cancel' | i18n}}" title="{{'cancel' | i18n}}"
            (click)="cancel()"><span aria-hidden="true">&times;</span></button>
        <h3 class="card-body-header">{{'addCredit' | i18n}}</h3>
        <div class="mb-4 text-lg" *ngIf="showOptions">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="Method" id="credit-method-paypal"
                    [value]="paymentMethodType.PayPal" [(ngModel)]="method">
                <label class="form-check-label" for="credit-method-paypal">
                    <i class="fa fa-fw fa-paypal"></i> PayPal</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="Method" id="credit-method-bitcoin"
                    [value]="paymentMethodType.BitPay" [(ngModel)]="method">
                <label class="form-check-label" for="credit-method-bitcoin">
                    <i class="fa fa-fw fa-bitcoin"></i> Bitcoin</label>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <label for="creditAmount">{{'amount' | i18n}}</label>
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text">$USD</span></div>
                        <input id="creditAmount" class="form-control" type="text" name="CreditAmount"
                            [(ngModel)]="creditAmount" (blur)="formatAmount()" required>
                    </div>
                </div>
            </div>
            <small class="form-text text-muted">{{'creditDelayed' | i18n}}</small>
        </div>
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading || ppLoading">
            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
            <span>{{'submit' | i18n}}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
            {{'cancel' | i18n}}
        </button>
    </div>
</form>
<form #ppButtonForm action="{{ppButtonFormAction}}" method="post" target="_top">
    <input type="hidden" name="cmd" value="_xclick">
    <input type="hidden" name="business" value="{{ppButtonBusinessId}}">
    <input type="hidden" name="button_subtype" value="services">
    <input type="hidden" name="no_note" value="1">
    <input type="hidden" name="no_shipping" value="1">
    <input type="hidden" name="rm" value="1">
    <input type="hidden" name="return" value="{{returnUrl}}">
    <input type="hidden" name="cancel_return" value="{{returnUrl}}">
    <input type="hidden" name="currency_code" value="USD">
    <input type="hidden" name="image_url" value="https://bitwarden.com/images/paypal-banner.png">
    <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynow_LG.gif:NonHosted">
    <input type="hidden" name="amount" value="{{creditAmount}}">
    <input type="hidden" name="custom" value="{{ppButtonCustomField}}">
    <input type="hidden" name="item_name" value="Bitwarden Account Credit">
    <input type="hidden" name="item_number" value="{{subject}}">
</form>
