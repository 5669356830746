<form #form class="card" (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
    <div class="card-body">
        <button type="button" class="close" attr.aria-label="{{'cancel' | i18n}}" title="{{'cancel' | i18n}}"
            (click)="cancel()"><span aria-hidden="true">&times;</span></button>
        <h3 class="card-body-header">{{(add ? 'addSeats' : 'removeSeats') | i18n}}</h3>
        <div class="row">
            <div class="form-group col-6">
                <label for="seatAdjustment">{{(add ? 'seatsToAdd' : 'seatsToRemove') | i18n}}</label>
                <input id="seatAdjustment" class="form-control" type="number" name="SeatAdjustment"
                    [(ngModel)]="seatAdjustment" min="0" step="1" required>
            </div>
        </div>
        <div *ngIf="add" class="mb-3">
            <strong>{{'total' | i18n}}:</strong> {{seatAdjustment || 0}} &times; {{seatPrice | currency:'$'}} = {{adjustedSeatTotal
            | currency:'$'}} /{{interval | i18n}}
        </div>
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
            <span>{{'submit' | i18n}}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
            {{'cancel' | i18n}}
        </button>
        <small class="d-block text-muted mt-3">
            {{(add ? 'seatsAddNote' : 'seatsRemoveNote') | i18n}}
        </small>
    </div>
</form>
