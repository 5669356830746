<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
    <div class="modal-body">
        <p>{{'twoStepLoginAuthDesc' | i18n}}</p>
        <label for="masterPassword">{{'masterPass' | i18n}}</label>
        <input id="masterPassword" type="password" name="MasterPasswordHash" class="form-control"
            [(ngModel)]="masterPassword" required appAutoFocus appInputVerbatim>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
            <span>{{'continue' | i18n}}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{'close' | i18n}}</button>
    </div>
</form>
