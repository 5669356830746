<div class="modal fade">
    <div class="modal-dialog modal-sm">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise">
            <div class="modal-header">
                <h2 class="modal-title">
                    {{'moveSelected' | i18n}}
                </h2>
                <button type="button" class="close" data-dismiss="modal" attr.aria-label="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{'moveSelectedItemsDesc' | i18n: cipherIds.length}}</p>
                <div class="form-group">
                    <label for="folder">{{'folder' | i18n}}</label>
                    <select id="folder" name="FolderId" [(ngModel)]="folderId" class="form-control">
                        <option *ngFor="let f of folders" [ngValue]="f.id">{{f.name}}</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal"
                    title="{{'cancel' | i18n}}">{{'cancel' | i18n}}</button>
            </div>
        </form>
    </div>
</div>
