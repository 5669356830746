<ng-container *ngIf="vault">
    <p *ngIf="!loaded" class="text-muted">
        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
    </p>
    <ng-container *ngIf="loaded">
        <ul class="fa-ul card-ul carets" *ngIf="organizations && organizations.length">
            <li *ngFor="let o of organizations">
                <a [routerLink]="['/organizations', o.id]" class="text-body">
                    <i class="fa-li fa fa-caret-right"></i> {{o.name}}
                    <i *ngIf="!o.enabled" class="fa fa-exclamation-triangle text-danger"
                        title="{{'organizationIsDisabled' | i18n}}"></i>
                </a>
            </li>
        </ul>
        <p *ngIf="!organizations || !organizations.length">{{'noOrganizationsList' | i18n}}</p>
    </ng-container>
    <a href="#" routerLink="/settings/create-organization" class="btn btn-block btn-outline-primary">
        <i class="fa fa-plus fa-fw"></i>
        {{'newOrganization' | i18n}}
    </a>
</ng-container>
<ng-container *ngIf="!vault">
    <div class="page-header d-flex">
        <h1>
            {{'organizations' | i18n}}
            <small [appApiAction]="actionPromise" #action>
                <i class="fa fa-spinner fa-spin text-muted" *ngIf="action.loading" title="{{'loading' | i18n}}"></i>
            </small>
        </h1>
        <a href="#" routerLink="/settings/create-organization" class="btn btn-sm btn-outline-primary ml-auto"
            *ngIf="!loaded || (organizations && organizations.length)">
            <i class="fa fa-plus fa-fw"></i>
            {{'newOrganization' | i18n}}
        </a>
    </div>
    <i class="fa fa-spinner fa-spin text-muted" *ngIf="!loaded" title="{{'loading' | i18n}}"></i>
    <ng-container *ngIf="loaded">
        <ng-container *ngIf="!organizations || !organizations.length">
            <p>{{'noOrganizationsList' | i18n}}</p>
            <a href="#" routerLink="/settings/create-organization" class="btn btn-outline-primary">
                <i class="fa fa-plus fa-fw"></i>
                {{'newOrganization' | i18n}}
            </a>
        </ng-container>
        <table class="table table-hover table-list" *ngIf="organizations && organizations.length">
            <tbody>
                <tr *ngFor="let o of organizations">
                    <td width="30">
                        <app-avatar [data]="o.name" size="25" [circle]="true" [fontSize]="14"></app-avatar>
                    </td>
                    <td>
                        <a href="#" [routerLink]="['/organizations', o.id]">{{o.name}}</a>
                        <i *ngIf="!o.enabled" class="fa fa-exclamation-triangle text-danger"
                            title="{{'organizationIsDisabled' | i18n}}"></i>
                    </td>
                    <td class="table-list-options">
                        <div class="dropdown" appListDropdown>
                            <button class="btn btn-outline-secondary dropdown-toggle" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-cog fa-lg"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item text-danger" href="#" appStopClick (click)="leave(o)">
                                    <i class="fa fa-fw fa-sign-out"></i>
                                    {{'leave' | i18n}}
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</ng-container>
