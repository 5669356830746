<div class="page-header">
    <h1>
        {{'premiumMembership' | i18n}}
        <small>
            <i class="fa fa-spinner fa-spin text-muted" *ngIf="firstLoaded && loading" title="{{'loading' | i18n}}"></i>
        </small>
    </h1>
</div>
<i class="fa fa-spinner fa-spin text-muted" *ngIf="!firstLoaded && loading" title="{{'loading' | i18n}}"></i>
<ng-container *ngIf="sub">
    <app-callout type="warning" title="{{'canceled' | i18n}}" *ngIf="subscription && subscription.cancelled">
        {{'subscriptionCanceled' | i18n}}</app-callout>
    <app-callout type="warning" title="{{'pendingCancellation' | i18n}}" *ngIf="subscriptionMarkedForCancel">
        <p>{{'subscriptionPendingCanceled' | i18n}}</p>
        <button #reinstateBtn type="button" class="btn btn-outline-secondary btn-submit" (click)="reinstate()"
            [appApiAction]="reinstatePromise" [disabled]="reinstateBtn.loading">
            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
            <span>{{'reinstateSubscription' | i18n}}</span>
        </button>
    </app-callout>
    <dl *ngIf="selfHosted">
        <dt>{{'expiration' | i18n}}</dt>
        <dd *ngIf="sub.expiration">{{sub.expiration | date:'mediumDate'}}</dd>
        <dd *ngIf="!sub.expiration">{{'neverExpires' | i18n}}</dd>
    </dl>
    <div class="row" *ngIf="!selfHosted">
        <div class="col-4">
            <dl>
                <dt>{{'status' | i18n}}</dt>
                <dd>
                    <span class="text-capitalize">{{(subscription && subscription.status) || '-'}}</span>
                    <span class="badge badge-warning"
                        *ngIf="subscriptionMarkedForCancel">{{'pendingCancellation' | i18n}}</span>
                </dd>
                <dt>{{'nextCharge' | i18n}}</dt>
                <dd>{{nextInvoice ? ((nextInvoice.date | date: 'mediumDate') + ', ' + (nextInvoice.amount | currency:'$')) :
                    '-'}}
                </dd>
            </dl>
        </div>
        <div class="col-8" *ngIf="subscription">
            <strong class="d-block mb-1">{{'details' | i18n}}</strong>
            <table class="table">
                <tbody>
                    <tr *ngFor="let i of subscription.items">
                        <td>
                            {{i.name}} {{i.quantity > 1 ? '&times;' + i.quantity : ''}} @ {{i.amount | currency:'$'}}
                        </td>
                        <td>
                            {{(i.quantity * i.amount) | currency:'$'}} /{{i.interval | i18n}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <ng-container *ngIf="selfHosted">
        <div>
            <button type="button" class="btn btn-outline-secondary" (click)="updateLicense()">
                {{'updateLicense' | i18n}}
            </button>
            <a href="https://vault.bitwarden.com/#/settings/subscription" target="_blank" rel="noopener"
                class="btn btn-outline-secondary">
                {{'manageSubscription' | i18n}}
            </a>
        </div>
        <div class="card mt-3" *ngIf="showUpdateLicense">
            <div class="card-body">
                <button type="button" class="close" attr.aria-label="{{'cancel' | i18n}}" title="{{'cancel' | i18n}}"
                    (click)="closeUpdateLicense(false)"><span aria-hidden="true">&times;</span></button>
                <h3 class="card-body-header">{{'updateLicense' | i18n}}</h3>
                <app-update-license (onUpdated)="closeUpdateLicense(true)" (onCanceled)="closeUpdateLicense(false)">
                </app-update-license>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!selfHosted">
        <div class="d-flex">
            <button type="button" class="btn btn-outline-secondary" (click)="downloadLicense()"
                *ngIf="!subscription || !subscription.cancelled">
                {{'downloadLicense' | i18n}}
            </button>
            <button #cancelBtn type="button" class="btn btn-outline-danger btn-submit ml-auto" (click)="cancel()"
                [appApiAction]="cancelPromise" [disabled]="cancelBtn.loading"
                *ngIf="subscription && !subscription.cancelled && !subscriptionMarkedForCancel">
                <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
                <span>{{'cancelSubscription' | i18n}}</span>
            </button>
        </div>
        <h2 class="spaced-header">{{'storage' | i18n}}</h2>
        <p>{{'subscriptionStorage' | i18n : sub.maxStorageGb || 0 : sub.storageName || '0 MB'}}</p>
        <div class="progress">
            <div class="progress-bar bg-success" role="progressbar" [ngStyle]="{width: storageProgressWidth + '%' }"
                [attr.aria-valuenow]="storagePercentage" aria-valuemin="0" aria-valuemax="100">
                {{(storagePercentage / 100) | percent}}</div>
        </div>
        <ng-container *ngIf="subscription && !subscription.cancelled && !subscriptionMarkedForCancel">
            <div class="mt-3">
                <div class="d-flex" *ngIf="!showAdjustStorage">
                    <button type="button" class="btn btn-outline-secondary" (click)="adjustStorage(true)">
                        {{'addStorage' | i18n}}
                    </button>
                    <button type="button" class="ml-1 btn btn-outline-secondary" (click)="adjustStorage(false)">
                        {{'removeStorage' | i18n}}
                    </button>
                </div>
                <app-adjust-storage [storageGbPrice]="4" [add]="adjustStorageAdd" (onAdjusted)="closeStorage(true)"
                    (onCanceled)="closeStorage(false)" *ngIf="showAdjustStorage"></app-adjust-storage>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
